@media only print {
  mat-drawer,
  app-breadcrumb,
  app-header .links-container,
  button,
  app-footer .version-container,
  app-footer .ans-img,
  app-cookie-acceptance,
  app-auth .bg-container,
  app-auth .main-buttons-container,
  app-filtro-relatorio-chamados,
  .chamados-cards-container,
  mat-paginator,
  app-loader {
    display: none !important;
  }

  app-header .ans-img {
    display: block !important;
  }

  mat-card.mat-card,
  .header-container {
    box-shadow: none !important;
  }

  mat-drawer-content.mat-drawer-content {
    margin-left: 0 !important;
  }

  app-auth app-payment-report-table {
    width: 700px !important;
  }

  *,
  .text-secondary,
  .text-primary {
    color: black !important;
  }

  td,
  th {
    padding: 0 0px !important;
  }

  .tabela-informe-pagamentos {
    font-size: 10px !important;
  }

  .desc-valor {
    font-size: 7px !important;
  }

  .espacamento {
    width: 35px !important;
  }

  .espacamento-desc {
    width: 50px !important;
  }

  app-relatorio-chamados {
    td,
    th {
      padding: 0 5px !important;
    }
  }
}
