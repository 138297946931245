@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap');
@import './assets/scss/variables.scss';
@import './assets/scss/theme.scss';

$enable-negative-margins: true;

@import '../node_modules/bootstrap/scss/bootstrap.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: 0;
}

body {
  background-color: #f1f1f1;

  --primaryColor: #65db78;
  --secondaryColor: #65db78;
}

.text-secondary {
  color: var(--secondaryColor) !important;
}

.text-primary {
  color: var(--primaryColor) !important;
}

html,
body,
input,
button,
textarea,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: 'Nunito', sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

html,
body {
  height: 100%;
}

mat-error + mat-error {
  display: none !important;
}

div.mat-form-field-subscript-wrapper {
  margin-top: 0.3rem !important;
}

app-auth {
  .auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    max-width: 450px;
    margin: 30px auto 0;
  }

  app-invoice-table,
  app-list-card-numbers {
    display: block;
    margin: 0 auto;
    max-width: 800px;
  }

  .links-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.7rem;
    margin-top: -10px;
    font-weight: 700;
    color: var(--primaryColor);

    > span {
      font-weight: 700;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: var(--secondaryColor);
      }
    }
  }

  app-payment-report-table {
    margin: 30px auto;
    width: 1000px;
    display: block;
    width: 1100px;
    margin: 30px auto;
    border: 1px solid gainsboro;
    border-radius: 8px;
    padding: 30px;
  }
}

.mat-drawer-inner-container {
  overflow-x: hidden !important;
  padding: 16px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #00abd6;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4dcff0;
}

::-webkit-scrollbar-track {
  background: #e5faff;
  border-radius: 16px;
}

.c-pointer {
  cursor: pointer;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  color: #ffffff;
}

.max-w-1000 {
  max-width: 1000px;
}

app-loader {
  position: absolute;
  top: 0;
  z-index: 999999;
}

.toast-message {
  font-size: 12px;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

td,
th {
  padding: 0 10px !important;
}

.flex-1 {
  flex: 1;
}

.badge {
  font-size: 12px;
  font-weight: 500;
}

@import './assets/scss/print.scss';

app-beneficiary-health-statement td {
  .mat-form-field-wrapper {
    padding-bottom: 10px;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 0.8rem !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.hyper-link {
  color: #0d6efd;
  text-decoration: underline;
}

.text-default {
  color: $defaultTextColor;
}

.mat-select-panel {
  max-width: unset !important;
}

.no-data-image {
  width: 110px;
  display: block;
  margin: 20px auto;
}
